@font-face { font-family: OpenSans; src: url('./assets/fonts/Open_Sans/OpenSans-Regular.ttf');} 
@font-face { font-family: OpenSans; font-weight: bold; src: url('./assets/fonts/Open_Sans/OpenSans-Bold.ttf');}

.App {
  /* text-align: center; */

  font-family: OpenSans, sans-serif;
}

a:hover {
    color: white;
    text-decoration: none;
}