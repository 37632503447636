body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.color-primary {
    color: var(--primary) !important;
}
.color-secondary {
    color: var(--secondary) !important;
}
.bg-primary {
    background-color: var(--primary) !important;
}
.bg-secondary {
    background-color: var(--secondary) !important;
}

/* .one_line_col > ul > li > a {
    color: var(--primary);
} */