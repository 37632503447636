.container {
    border: 0px solid black;
    display: flex;
    flex-direction: row;
    padding: 10px 0px 10px 0px;
    min-height: 300px;
    margin-bottom: 30px;

    opacity: 0;
}

.textContainer {
    flex: 2;
    align-self: center;
}

.TitlePage {
    font-size: 30px;
    align-self: flex-start;
    font-weight: bold;
    margin: 0px 0px 10px 0px;
}
.TextPage {
    font-size: 20px;
    align-self: flex-start;
    margin: 0px;
    /* padding-left: 20px; */
}
.imagePage {
    height: 100%;
    /* max-width: 33%; */
    flex: 1;
    justify-self: center;
    object-fit: cover;
}

.animContainer {
    border: 0px solid black;
    display: flex;
    flex-direction: row;
    padding: 10px 0px 10px 0px;
    min-height: 300px;
    margin-bottom: 50px;

    -webkit-animation: fadein 1s ease-in; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s ease-in; /* Firefox < 16 */
    -ms-animation: fadein 1s ease-in; /* Internet Explorer */
    -o-animation: fadein 1s ease-in; /* Opera < 12.1 */
    animation: fadein 1s ease-in;
}
@keyframes showWithFade {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}